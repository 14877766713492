<template>
	<competition-assignments-page url="/v1/views/competition-planner"
								  :title="t('label.competition_planner')"
								  :feature-title="t('view.competition_planner.title')"
								  :feature-description="t('view.competition_planner.description')"
								  :community-feature-title="t('view.competition_planner.community.title')"
								  :community-feature-description="t('view.competition_planner.community.description')"
								  feature-image="/features/athletes/event-planner.svg"
								  :item-link="{to: '/events', text: t('label.competition_calendar')}">

		<template v-if="$auth.id" #action_buttons>
			<btn :title="t('action.add_to_calendar')"
				 color="success"
				 shadow
				 rounded-large
				 @click="isDownloadModalActive = true">
				<icon :icon="icons.download"/>
			</btn>

			<download-calendar-modal v-model="isDownloadModalActive"
									 :action="`/v1/athletes/${$auth.id}/competition-planner/download`" analytics-type="competition-planner"/>
		</template>

	</competition-assignments-page>
</template>

<script setup lang="ts">
import {downloadIcon} from "@spoferan/nuxt-spoferan/icons";

const {t} = useI18n();

const icons = {
	download: downloadIcon
}

const isDownloadModalActive = ref(false);

useMeta({
	title: t('view.competition_planner.title'),
	description: t('view.competition_planner.description'),
})

</script>

